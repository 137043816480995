<template>
  <div>
    <van-tabs v-model="active" color="#ff98a0" :line-width="80">
      <van-tab :title="'电子奖券('+couponList.length+')'">
        <div class="voucher-list">
          <div class="list" :class="{'gray':[4,5].indexOf(item.State) >= 0 }" v-for="(item,index) in couponList" :key="index">
            <span class="look-source" @click="sourceByCoupon = item">查看来源</span>
            <div class="win-prize">
              <p class="print" v-if="item.State === 1">待激活打印</p>
              <!-- {{item.StartTime}}<br /> -->
              <p class="pad" v-else-if="item.State === 2">No.{{item.CouponCode}}</p>
              <p class="pad" v-else>已激活</p>
              <div class="prize-name" v-if="item.PrizeGrade && item.PrizeGradeName">
                <p class="ellipsis1">{{item.PrizeGradeName}}</p>
                <span @click="pageJump(1,'/recruit/html/AnimalLogistics.html')" class="view-delivery" v-if="item.ExpressNum">查看物流</span>
              </div>
              <img v-else src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901105640988.png" class="logo-small">
            </div>
            <img v-if="item.State === 5" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901105548003.png" class="chapter" />
            <img v-else-if="item.State === 4" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901105603347.png" class="chapter" />
            <img v-else-if="item.State === 3 && !item.PrizeGrade" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210901105527331.png" class="chapter">
          </div>
        </div>
        <div class="empty-content" v-if="couponList.length <= 0">
          <img src="../../assets/images/empty/coupon.png" />
          <p>暂无奖券</p>
        </div>
      </van-tab>
      <!--            <van-tab title="中奖记录">内容 2</van-tab>-->
    </van-tabs>
    <div class="layer" v-if="sourceByCoupon">
      <div class="source">
        <span class="modal-cha icon--4" @click="sourceByCoupon = null"></span>
        <div class="so-tit">奖券来源</div>
        <div class="so-cont">
          <p><span>来源：</span>{{sourceByCoupon.Source}}</p>
          <p><span>时间：</span>{{sourceByCoupon.SourceTime}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceAppToken } from '../../utils/appUtil'

export default {
  name: "miLive",
  data() {
    return {
      active: 0,
      couponList: [],
      sourceByCoupon: null,
      token: ''
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) {
      this.token = token;
      replaceAppToken(token, () => {
        this.getCouponList();
      });
    } else {
      this.getCouponList();
    }

  },
  methods: {
    getCouponList: function () {
      this.$toast.loading({ message: "加载中...", duration: 0, mask: true });
      this.get("/MiMall/AnnualMeeting/GetUserCoupons").then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.couponList = json.response.coupons;
        }
      });
    },
    pageJump(type, path) {
      if (!path) return;
      if (type === 1) {
        window.location.href = this.baseHost + "/7999" + path;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.van-tabs {
  /deep/ .van-tabs__wrap {
    position: relative;
    z-index: 100;

    .van-tabs__nav {
      .van-tab {
        &.van-tab--active {
          color: #ff98a0;
        }
      }
    }
  }

  /deep/ .van-tabs__content {
    position: fixed;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 80;
    overflow-y: auto;
    background-color: #f8f8f8;
  }
}

.voucher-list {
  padding: 15px 15px;

  .list {
    height: 110px;
    margin-top: 10px;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230411100307813.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .look-source {
      position: absolute;
      left: 2%;
      bottom: 10px;
      font-size: 9px;
      color: #fff;
      background: #8db1c1;
      display: inline-block;
      border-radius: 20px;
      line-height: 12px;
      padding: 2px 10px;
      text-align: center;
    }

    .win-prize {
      width: 27%;
      display: inline-block;
      text-align: center;

      p {
        font-size: 12px;
        color: #fff;

        &.pad {
          padding: 12px 0;
        }

        &.print {
          font-size: 9px;
          padding: 8px 10px;
          text-align: center;
          line-height: 13px;
        }
      }

      .prize-name {
        padding: 0 5px;

        p {
          color: #fff;
          font-size: 22px;

          &:nth-child(2) {
            margin: 6px 0;
            font-size: 12px;
          }
        }
      }

      .view-delivery {
        color: #fff;
        padding-top: 10px;
        display: block;
        position: relative;
        z-index: 10;
      }

      .prize-img {
        width: 35px;
        max-height: 40px;
      }

      .logo-small {
        width: 42px;
        height: 58px;
      }
    }

    .chapter {
      width: 81px;
      height: 61px;
      position: absolute;
      bottom: 0;
      right: 12%;
    }

    &:first-child {
      margin-top: 0;
    }

    &.gray {
      background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230411100334246.png")
        no-repeat;
      background-size: 100% 100%;

      .look-source {
        background-color: #aaaaaa;
      }
    }
  }
}

.layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 88;

  .source {
    width: 300px;
    height: 130px;
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 65px);
    background: #fff;
    border-radius: 6px;

    .modal-cha {
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 50%;
      background: #f1f1f1;
      position: absolute;
      right: 0;
      top: 0;
      margin: 10px;
      color: #666;
      text-align: center;
      line-height: 24px;
      font-size: 12px;

      &:before {
        content: "\e9c5";
      }
    }

    .so-tit {
      font-size: 16px;
      color: #666666;
      padding: 15px 0;
      text-align: center;
    }

    .so-cont {
      padding: 0 30px;
      line-height: 24px;
      text-align: left;

      p {
        font-size: 12px;
        color: #666;

        span {
          color: #999;
        }
      }
    }
  }

  .stage {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

    img {
      max-width: 100%;
    }
  }
}

.empty-content {
  padding-top: 20%;

  img {
    width: 50%;
  }

  p {
    color: #999;
    letter-spacing: 2px;
    font-size: 16px;
    padding-top: 10px;
  }
}
</style>
